module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["ja","en","zh-CN","ko","zh-TW"],"defaultLanguage":"ja","fallbackLanguage":"ja","siteUrl":"https://stay.rakuten.co.jp","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"react":{"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","b","i","span","strong"]}},"pages":[{"matchPath":"/404","languages":["ja"]},{"matchPath":"/dormitory","languages":["ja"]},{"matchPath":"/items/:slug?","languages":["ja"]},{"matchPath":"/app/download","languages":["ja"]},{"matchPath":"/app/share","languages":["ja"]},{"matchPath":"/news/:dir?/:slug?","languages":["ja"]},{"matchPath":"/features/:dir?/:slug?","languages":["ja"]},{"matchPath":"/qr/:slug","languages":["ja"]},{"matchPath":"/hm/:slug","languages":["ja"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TGQ3M4K","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
